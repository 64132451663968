<template>
  <Layout :pagetitle="'Profile'">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xxl-4">
        <div class="user-sidebar">
          <div class="card">
            <div class="card-body p-0">
              <div class="user-profile-img">
                <img
                  src="@/assets/images/bg-profile.jpg"
                  class="profile-img rounded-top"
                  style="height: 100px; background-size: cover"
                  alt=""
                />
                <div :class="`overlay-content rounded-top bg-${company.color ? company.color : 'primary'}`">
                  <!--<span class="h2 text-white text-center p-3 mt-2">Perfil</span>-->
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <div :class="`profile-avatar bg-${company.color ? company.color : 'primary'}`">
                  {{ user?.name[0] }}
                </div>
              </div>

              <div class="p-3 mt-5">
                <div>
                  <label class="form-label" for="billing-name">Nome*</label>
                  <input
                    type="text"
                    class="form-control"
                    id="billing-name"
                    placeholder="Informe o nome"
                    v-model="user.name"
                  />
                </div>

                <div class="mt-3">
                  <label class="form-label" for="billing-email">E-mail*</label>
                  <input
                    type="text"
                    class="form-control"
                    id="billing-email"
                    placeholder="Informe o e-mail"
                    v-model="user.email"
                  />
                </div>

                <div class="mt-3">
                  <label class="form-label" for="date-of-birth">Data de Nascimento</label>
                  <input
                    class="form-control"
                    type="date"
                    id="date-of-birth"
                    v-model="user.dateOfBirth"
                  />
                </div>

                <label class="form-label mt-3">Senha</label>
                <div class="d-flex justify-content-between">
                  <span class="h6 mx-2 pt-1">*******</span>
                  <button
                    class="btn btn-sm btn-rounded waves-effect waves-light col-3 col-lg-2 btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target=".modal-update-password"
                  >
                    <span>Editar</span>
                  </button>
                </div>
              </div>

              <div class="my-3 px-3 d-flex justify-content-center">
                <div
                  v-if="isLoading"
                  :class="`spinner-border m-1 text-${company.color ? company.color : 'primary'}`"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>

                <div v-else class="col-12 d-flex justify-content-center">
                  <button
                    :class="`btn btn-rounded waves-effect waves-light col-5 col-md-4 ml-2 btn-${company.color ? company.color : 'primary'}`"
                    @click="$router.push({ name: 'companyPrincipal' })"
                  >
                    <span>Voltar</span>
                  </button>

                  <button
                    :class="`btn btn-rounded waves-effect waves-light col-5 col-md-4 mx-2 btn-${company.color ? company.color : 'primary'}`"
                    @click="onSave()"
                  >
                    <span>Salvar</span>
                  </button>
                </div>
              </div>
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
    </div>
    <!-- modal-update-password -->
    <div
      class="modal fade modal-update-password"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mySmallModalLabel">Alterar Senha</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="closeButton"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mt-2">
              <label class="form-label">Senha Atual</label>
              <input
                class="form-control"
                type="password"
                v-model="currentPassword"
              />

              <label class="form-label mt-3">Nova Senha</label>
              <input
                class="form-control"
                type="password"
                v-model="newPassword"
              />

              <label class="form-label mt-3">Confirmar Nova Senha</label>
              <input
                class="form-control"
                type="password"
                v-model="newPasswordConfirm"
              />

              <div class="d-flex justify-content-center" v-if="isLoading">
                <div
                  :class="`spinner-border m-1 mt-3 text-${company.color ? company.color : 'primary'}`"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

              <button
                v-else
                :class="`btn btn-rounded waves-effect waves-light col-5 col-md-4 mt-4 w-100 btn-${company.color ? company.color : 'primary'}`"
                @click="updatePassword()"
              >
                <span>Salvar Alterações</span>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal-update-password -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      company: JSON.parse(localStorage.getItem("company")),
      user: {
        name: null,
        email: null,
        dateOfBirth: null,
      },
      currentPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
    };
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState(["isLoading"]),
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user.dateOfBirth) {
      const fullDate = new Date(this.user.dateOfBirth);
      this.user.dateOfBirth = fullDate.toISOString().split("T")[0];
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    onSave() {
      if (!this.user.name || this.user.name.length < 3) {
        this.$customAlert({
          type: "warning",
          msg: "Informe um nome",
        });
        return;
      }
      if (!this.$utils.emailValidation(this.user.email)) return;
      if (
        this.user.dateOfBirth &&
        !this.$utils.validateDateOfBirth(this.user.dateOfBirth, 10, 100)
      )
        return;

      const payload = {
        name: this.user.name,
        email: this.user.email,
      };

      if (this.user.dateOfBirth) {
        payload.dateOfBirth = this.user.dateOfBirth;
      }

      this.setLoading(true);
      this.$api
        .put(`user/${this.user._id}`, payload)
        .then((res) => {
          this.setLoading(false);
          localStorage.setItem("user", JSON.stringify(res.data));
          this.$router.push({ name: "companyPrincipal" });
        })
        .catch((err) => {
          this.setLoading(false);
          this.$customAlert({
            type: "error",
            msg: err?.data?.message || "Erro ao atualizar usuário",
          });
        });
    },
    updatePassword() {
      if (!this.currentPassword) {
        this.$customAlert({
          type: "warning",
          msg: "Informe a senha atual",
        });
        return;
      }
      if (!this.$utils.validatePassword(this.newPassword)) return;
      if (!this.$utils.validatePassword(this.newPasswordConfirm)) return;
      if (this.newPassword !== this.newPasswordConfirm) {
        this.$customAlert({
          type: "warning",
          msg: "A nova senha e a confirmação devem ser iguais",
        });
        return;
      }

      const payload = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      };

      this.setLoading(true);
      this.$api
        .put(`auth/updatePassword/${this.user._id}`, payload)
        .then(() => {
          this.setLoading(false);
          this.$customAlert({
            type: "success",
            msg: "Senha atualizada com sucesso",
          });
          const closeButton = this.$refs.closeButton;
          closeButton.click();
        })
        .catch((err) => {
          this.setLoading(false);
          this.$customAlert({
            type: "error",
            msg: err?.data?.message || "Erro ao atualizar senha",
          });
        });
    },
  },
};
</script>

<style scoped>
.profile-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid #fff;
  position: absolute;
  top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #fff;
}
</style>
